const instagramRegExp = new RegExp(/<script type="text\/javascript">window\._sharedData = (.*);<\/script>/);
const feedWrapper = document.querySelector('.instagram-feed .row-flex');
const instagramURL = 'https://www.instagram.com/tubos_oliveira/';

const fetchInstagramPhotos = async (accountUrl) => {
	const response = await axios.get(accountUrl)
	const json = JSON.parse(response.data.match(instagramRegExp)[1])
	const edges = json.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 9)
	const photos = edges.map(({ node }) => {
		return {
			url: `https://www.instagram.com/p/${node.shortcode}/`,
			thumbnailUrl: node.thumbnail_resources[1],
			displayUrl: node.display_url,
			caption: node.edge_media_to_caption.edges[0].node.text
		}
	})
	return photos
}

const buildInstagramFeed = photos => {
	let feedItems = ``

	photos.map(item => {
		feedItems += `
			<div class="col-sm-4 col-xs-6">
				<a href="${item.url}" target="_blank">
					<img src="${item.thumbnailUrl.src}" alt="${item.caption}" title="${item.caption}" />
				</a>
			</div>
		`
	})
	if ( feedWrapper !== null ) {
		feedWrapper.innerHTML = feedItems
	}
}

window.addEventListener('load', () => {

	let instaMutation = new MutationObserver(mutations => {
		mutations.forEach(mutation => {
			if( mutation.attributeName == "data-loaded" ) {
				// console.log('safe')
				(async () => {
					try {
						const photos = await fetchInstagramPhotos(instagramURL)
			
						buildInstagramFeed(photos)
					} catch (e) {
						console.error('Fetching Instagram photos failed', e)
						feedWrapper.innerHTML =
						`<p>
							Houve um erro na importação das imagens, para acessar nosso Instagram clique <a href="${instagramURL}" title="Nosso Instagram" target="_BLANK">AQUI</a>
						</p>`;
					}
				})();
			}
			
			instaMutation.disconnect()
		})
	})

	instaMutation.observe(document.querySelector('.instagram-feed'), { attributes: true })

});