
// hero
$('.feed-instagram').owlCarousel({
    items: 3,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    animateOut: 'fadeOut',
    autoplayTimeout: 2500,
    margin: 0,
    navText: [
        '<i class="fa fa-angle-double-left"></i> Deslize para ver mais.',
        '<i class="fa fa-angle-double-right"></i>'
    ],
});


// hero feed
$('.home-carousel').owlCarousel({
    items: 1,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    animateOut: 'fadeOut',
    autoplayTimeout: 5500,
    autoHeight:true,
    margin: 0,
    // navText: [
    //     '<i class="fa fa-angle-double-left"></i> Deslize para ver mais.',
    //     '<i class="fa fa-angle-double-right"></i>'
    // ],
});

// gallery mobile product
$('.product-carousel').owlCarousel({
    items: 1,
    loop: true,
    nav: true,
    dots: false,
    autoplay: false,
    animateOut: 'fadeOut',
    margin: 0,
    navText: [
        '<i class="fa fa-angle-double-left"></i> Deslize para ver mais.',
        '<i class="fa fa-angle-double-right"></i>'
    ],
    responsive: {
        0: {
            items: 3
        },
        768: {
            items: 3
        },
    },
});

// gallery mobile product
// $('.product-carousel2').owlCarousel({
//     items: 1,
//     loop: true,
//     nav: true,
//     dots: false,
//     autoplay: false,
//     animateOut: 'fadeOut',
//     margin: 0,
//     navText: [
//         '<i class="fa fa-angle-double-left"></i> Deslize para ver mais.',
//         '<i class="fa fa-angle-double-right"></i>'
//     ],
//     responsive: {
//         0: {
//             items: 3
//         },
//         768: {
//             items: 6
//         },
//     },
// });

//Animate scroll
function animateScroll(dest) {
    var scrollPosition = $(dest).offset().top - 0;
    $('html,body').animate({
        scrollTop: scrollPosition
    }, 500);
};

//Animate scroll same page links
$("[href*='#']").on("click", function (e) {
    e.preventDefault();
    var dest = $(this).attr('href');
    animateScroll(dest);
    $("body").toggleClass("menu-open");
});

function runAnimations() {
    $.each($('.animate'), function (index, element) {
        if ($(this).shouldAnimate()) {
            $(this).removeClass('animate');
            $(this).addClass('animated');
            $(this).addClass($(this).data('animation'));
        }
    });
}
runAnimations();

$(document).on('scroll', function () {
    runAnimations();
});

// Fixed Menu
function fixMenu() {
    if ($(window).scrollTop() > 70) {
        $('body').addClass('fixed-menu');
    } else {
        $('body').removeClass('fixed-menu');
    }
}
fixMenu();

$(document).on('scroll', function () {
    fixMenu();
});

// Open menu
$('.main-menu-toggle').on('click', function () {
    $('body').toggleClass('menu-open');
});
// Open side menu
$('.menu-button, .body-wrapper-full').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('menu-open');
})


// Projects Lightbox
$('.service-item').lightGallery({
    thumbnail: true
});

// lity
$(document).on('click', '[data-lightbox]', lity);

// Projects Lightbox
$('.project-item, .about-item').lightGallery({
    thumbnail: true,
});

// $('.about-item .owl-stage-outer .owl-stage .owl-item').each(function (){
//    const imgUrl = $(this).find('div').attr('data-src');
//    $(this).attr('data-src', imgUrl);
// });


/**
 * @by Ag. 3xceler / André Facchini -> em diante.
 *
*/

var wpVars = window.wpVars !== null ? JSON.parse(window.wpVars) : false;

$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

/**
 *
 * @method deferIframeLoads
 *
 * Make iframes load after full page load for better performance
 *
*/

function deferIframeLoads(timeout) {
    window.setTimeout(function () {
        $('iframe').each(function () {
            $(this).prop('src', $(this).data('src'));
        });
    }, timeout);
}

/**
 * @method formHandling
 * @see https://api.jquery.com/jquery.ajax/
 * @param evt Event handler
*/

function formHandling(evt) {
    evt.preventDefault();

    var formData = $(this).serializeObject();
    var button = $(this).find('button');
    var actionName = 'tb_submit_emails'

    button.addClass('btn-disabled loading');

    var objToSubmit = {
        action: actionName,
        values: formData
    }
    
    
    
    $.ajax({
        type: 'POST',
        url: wpVars.adminURL,
        data: objToSubmit,
        success: function (response) {

         
            Swal.fire(
                'Obrigado!',
                'Seu E-mail foi enviado com sucesso! <br> Nossos analistas retornarão o mais breve possível.',
                'success'
            );

            ga('gtag_UA_135038038_1.send', "event", "Formulário", "Envio", "Formulários de Contato");
    
            button.removeClass('btn-disabled loading');

        },
        fail: function (error) {
            Swal.fire(
                'Ops!',
                'Houve um problema com o envio do seu E-mail. <br> Por favor tente novamente mais tarde ou entre em contato através de um dos nossos telefones.',
                'error'
            );
            button.removeClass('btn-disabled loading');
        },
        always: function (operation) {
            button.removeClass('btn-disabled loading');
        }
    });

}


/**
 * @method maskInstancing
 * @uses jquery.mask.js <- on src/js/d.mask.js
 *
 * Basic form field masks
*/

function maskInstancing() {
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
        spOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

    $('input[name="Telefone"], input[name="Celular"], input[name="Telefone Fixo"]').mask(SPMaskBehavior, spOptions);
    $('input[name="CNPJ"]').mask('99.999.999/9999-99');
}

const appendZendesk = () => {
    if (window.innerWidth > 1000) {
        let script = document.createElement('script');
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=3b6ae779-112d-449f-9821-0180808cbe2c';
        script.type = 'text/javascript';
        script.setAttribute('id', 'ze-snippet');
        let footer = document.querySelector('footer');
        footer.appendChild(script);
    }
}


// Cookies 

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + '=';
    var begin = dc.indexOf('; ' + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(';', begin);
        if (end == -1) {
            end = dc.length;
        }
    }

    return decodeURI(dc.substring(begin + prefix.length, end));
}



/**
 * method binding after window load.
*/

window.addEventListener('load', () => {
    deferIframeLoads(3000);
    maskInstancing();
    $('.cta-form').on('submit', formHandling)
    $('.orcamento-popup-form').on('submit', formHandling)
    $('.contato-produto').on('submit', formHandling)
    
    appendZendesk();


});

window.onload = () => {
    let observer = lozad()
    observer.observe()
}

