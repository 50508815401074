$(document).ready(function () {
  let formData;

  $('.filtro--select').on('change', () => {
    let url = window.location.href.split('?')[0];
    let selects = $('.filtro--select');
    let request = '?';

    selects.each((index, select) => {
      select = $(select);

      if (select.val() !== null && select.val() !== 'todos') {
        request += select.attr('id') + '=' + select.val() + '&';
      }
    });

    window.location = url + request;
  });

  // Para página de downloads
  $('a[data-email-download]').on('click', (e) => {
    e.preventDefault();
    let btn = $(e.target);
    let container = $('.modal__container');

    container.find('form').attr('data-url', btn.attr('data-url'));
    container.find('form').attr('data-name', btn.attr('data-name'));
    MicroModal.show('modal-download-cta');
  });

  $('#modal-download-cta form').on('submit', (e) => {
    e.preventDefault();
    let btn = document.querySelector('.submit-download');
    window.open(btn.parentNode.getAttribute('data-url'));
    let toSubmit = {};

    formData = new FormData(e.target);
    toSubmit['Documento'] = btn.parentNode.getAttribute('data-name');
    formData.forEach((value, key) => {
      toSubmit[key] = value;
    });
    toSubmit['action'] = 'get_emails_post';

    let isRegistered = $.post({
      url: wpVars.adminURL,
      data: {
        action: 'get_emails_post',
        values: toSubmit,
      },
      beforeSend: function () {
        btn.setAttribute('disabled', 'disabled');
      },
    });

    isRegistered.done((response) => {
      ga('gtag_UA_135038038_1.send', 'event', 'Formulário', 'Envio', 'Catálogo');

      MicroModal.close('modal-download-cta');

      Swal.fire(
        'Obrigado!',
        'Você recebeu o acesso ao nosso catálogo em uma nova janela.',
        'success',
      );

      e.target.reset();
      btn.removeAttribute('disabled');
    });
  });

  // Para Home
  $('form.form-download-catalogo').on('submit', (e) => {
    e.preventDefault();
    // window.open(
    //   'https://www.tubosoliveira.com.br/wp-content/uploads/2020/06/Catálogo-Tubos-Oliveira.pdf',
    // );

    let btn = document.querySelector('button[data-catalogo-download]');
    let toSubmit = {};
    let formData = new FormData(e.target);
    formData.forEach((value, key) => {
      toSubmit[key] = value;
    });

    let isRegistered = $.post({
      url: wpVars.adminURL,
      data: {
        action: 'get_emails_post',
        values: toSubmit,
      },
      beforeSend: function () {
        btn.setAttribute('disabled', 'disabled');
      },
    });

    let isSended = $.post({
      url: wpVars.adminURL,
      data: {
        action: 'enviaDados',
        values: toSubmit,
      },
    });

    isSended.done((response) => {
      ga('gtag_UA_135038038_1.send', 'event', 'Formulário', 'Envio', 'Catálogo');
      console.log(isSended)
      Swal.fire(
        'Obrigado!',
        'Você recebeu o acesso ao nosso catálogo por e-mail.',
        'success',
      );

      e.target.reset();
      btn.removeAttribute('disabled');
    });
  });
});
